<template>
  <v-container fluid class="down-top-padding">
    <vue-advanced-chat
      :current-user-id="currentUserId"
      :rooms="JSON.stringify(rooms)"
      :messages="JSON.stringify(messages)"
      :room-actions="JSON.stringify(roomActions)"
      :text-messages="JSON.stringify(textMessages)"
    />
  </v-container>
</template>

<script>
import { register } from "vue-advanced-chat";
register();

export default {
  data() {
    return {
      currentUserId: "1234",
      rooms: [],
      messages: [],
      roomActions: [
        { name: "inviteUser", title: "Invite User" },
        { name: "removeUser", title: "Remove User" },
        { name: "deleteRoom", title: "Delete Room" },
      ],
      textMessages: {
        ROOMS_EMPTY: "ルームがありません",
        ROOM_EMPTY: "ルームがありません",
        NEW_MESSAGES: "新しいメッセージ",
        MESSAGE_DELETED: "メッセージ削除",
        MESSAGES_EMPTY: "メッセージがありません",
        CONVERSATION_STARTED: "チャット開始",
        TYPE_MESSAGE: "Tapez votre message",
        SEARCH: "検索",
        IS_ONLINE: "オンライン",
        LAST_SEEN: "最後のメッセージ",
        IS_TYPING: "入力中...",
        CANCEL_SELECT_MESSAGE: "メッセージのキャンセル",
      },
    };
  },
  methods: {},
};
</script>
